import React from 'react';
import { css } from '@emotion/core';
import { animated, useTransition } from 'react-spring';

import Image from '../components/Image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { container, title } from '../styles';

const flexWrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 37rem) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const imageWrapper = css`
    flex: 1 1 30%;
    width: 100%;
    max-width: 400px;
`;

const form = css`
    flex: 1 1 70%;
    width: 100%;
    line-height: 1.8;
    font-family: "DM San's", sans-serif;

    @media (min-width: 37rem) {
        margin-right: 2rem;
    }

    @media (min-width: 50rem) {
        margin-right: 4rem;
    }
`;

const controlGroup = css`
    @media (min-width: 25rem) {
        display: flex;
        justify-content: space-between;

        > * {
            flex: 0 0 48%;
        }
    }
`;

const formControl = css`
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem;
    text-align: left;

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 0.2rem 0.5rem;
        margin-bottom: 1rem;
        border: 2px solid #e0e0e0;
        transition: border 0.3s;
        /* border-radius: 0.2rem; */

        &:focus {
            outline: none;
            border: 2px solid #f48fb1;
        }
    }

    textarea {
        min-height: 8rem;
    }
`;

const bottomWrapper = css`
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
`;

const messageWrapper = css`
    flex: 1 1 70%;
    margin: 0;
    padding-right: 1rem;
    position: relative;
`;

const message = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 1.4;
`;

const submit = css`
    flex: 0 0 auto;
    border: none;
    background: #2d2d2d;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    margin-left: auto;
    transition: background 0.3s;

    &:hover {
        cursor: pointer;
        background: #f48fb1;
    }

    &:disabled {
        cursor: default;
        background: #ddd;
    }
`;

const encode = data => {
    return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
};

const ContactPage = () => {
    const [state, setState] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
    });
    const [status, setStatus] = React.useState('');
    const transitions = useTransition(status, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });

    const handleControlChange = event => {
        setState({
            ...state,
            [event.target.id]: event.target.value,
        });
    };

    const handleSubmit = event => {
        event.preventDefault();

        grecaptcha.ready(() => {
            grecaptcha.execute(window.GRECAPTCHA_KEY, { action: 'contact' }).then(token => {
                const request = new XMLHttpRequest();

                request.open('POST', '/', true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                request.onreadystatechange = () => {
                    if (request.readyState === 4) {
                        switch (request.status) {
                            case 200:
                                setStatus("Thanks! I'll get back to you shortly.");
                                setState({
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                    subject: '',
                                    message: '',
                                });
                                break;
                            default:
                                setStatus('There was an issue submitting the form. Please try again.');
                                setTimeout(() => setStatus(''), 5000);
                        }
                    }
                };
                request.send(
                    encode({
                        'g-recaptcha-response': token,
                        'form-name': 'contact',
                        'first-name': state.firstName,
                        'last-name': state.lastName,
                        email: state.email,
                        subject: state.subject,
                        message: state.message,
                    })
                );
            });
        });
    };

    return (
        <Layout>
            <SEO title="About" />
            <div css={container}>
                <h1 css={title}>Contact</h1>
                <div css={flexWrapper}>
                    <form css={form} name="contact" data-netlify="true" onSubmit={handleSubmit}>
                        <input type="hidden" name="form-name" value="contact" />
                        <div css={controlGroup}>
                            <label css={formControl} htmlFor="firstName">
                                First Name
                                <input
                                    id="firstName"
                                    name="first-name"
                                    type="text"
                                    value={state.firstName}
                                    onChange={handleControlChange}
                                    required
                                />
                            </label>
                            <label css={formControl} htmlFor="lastName">
                                Last Name
                                <input
                                    id="lastName"
                                    name="last-name"
                                    type="text"
                                    value={state.lastName}
                                    onChange={handleControlChange}
                                    required
                                />
                            </label>
                        </div>
                        <label css={formControl} htmlFor="email">
                            Email
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={state.email}
                                onChange={handleControlChange}
                                required
                            />
                        </label>
                        <label css={formControl} htmlFor="subject">
                            Subject
                            <input
                                id="subject"
                                type="text"
                                value={state.subject}
                                onChange={handleControlChange}
                                required
                            />
                        </label>
                        <label css={formControl} htmlFor="message">
                            Message
                            <textarea
                                id="message"
                                name="message"
                                value={state.message}
                                onChange={handleControlChange}
                                required
                            />
                        </label>
                        <div css={bottomWrapper}>
                            <div css={messageWrapper}>
                                {transitions.map(
                                    ({ item, key, props: { opacity } }) =>
                                        item && (
                                            <animated.p
                                                css={message}
                                                key={key}
                                                style={{
                                                    opacity,
                                                    transform: opacity.interpolate(o => `translateY(${o * -50}%`),
                                                }}
                                            >
                                                {item}
                                            </animated.p>
                                        )
                                )}
                            </div>
                            <button css={submit} type="submit" disabled={!!status}>
                                Submit
                            </button>
                        </div>
                    </form>
                    <div css={imageWrapper}>
                        <Image name="pose" />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactPage;
